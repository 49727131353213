import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
const Web3 = require("web3");

export default function MinerInfo(props) {
    const web3 = new Web3(process.env.REACT_APP_RPC);
    const { account, isActive, connector } = useWeb3React();
    const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
    const MINING_ADDRESS = process.env.REACT_APP_MINING_ADDR;
    let navigate = useNavigate();

    const [stakePend, setStakePend] = useState(0);
    const [unstakePend, setUnstakePend] = useState(0);

    const getSummary = async () => {
        if (localStorage.getItem("StakePending"))
            setStakePend(JSON.parse(localStorage.getItem("StakePending")).length);
        if (localStorage.getItem("UnstakePending"))
            setUnstakePend(JSON.parse(localStorage.getItem("UnstakePending")).length);
    };

    function getMinerInfo() {
        console.log("f!!running")
        if (localStorage.getItem("StakePending")) {
            var stp = JSON.parse(localStorage.getItem("StakePending"));
            stp.map((element) => {
                const url =
                    "https://egold-miner.tagdev.info/staketransaction/" + element;

                fetch(url)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (statdata) {
                        console.log("f!!running !stat", statdata)
                        if (statdata.status == true) {
                            stp = stp.filter((item) => item !== element);
                            console.log("f!!running !stat", statdata, stp)
                            if (stp.length == 0) {
                                localStorage.removeItem("StakePending");
                                setStakePend(0);
                                props.setpend(0)
                            } else {
                                localStorage.setItem("StakePending", JSON.stringify(stp));
                                setStakePend(JSON.parse(stp).length);
                                props.setpend(JSON.parse(stp).length)
                            }
                            props.summaryfetch();
                        }
                    })
                    .catch(function (error) {
                        console.log("Requestfailed", error);
                    });
            });
        }
        if (localStorage.getItem("UnstakePending")) {
            var ustp = JSON.parse(localStorage.getItem("UnstakePending"));
            ustp.map((element) => {
                const url =
                    "https://egold-miner.tagdev.info/unstaketransaction/" + element;

                fetch(url)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (statdata) {

                        if (statdata.status == true) {
                            ustp = ustp.filter((item) => item !== element);
                            console.log("f!!running !stat", statdata, ustp)
                            if (ustp.length == 0) {
                                localStorage.removeItem("UnstakePending");
                                setUnstakePend(0);
                                props.setunstakepend(0)
                            } else {
                                localStorage.setItem("UnstakePending", JSON.stringify(ustp));
                                setUnstakePend(JSON.parse(ustp).length);
                                props.setunstakepend(JSON.parse(ustp).length)
                            }
                            props.summaryfetch();
                        }
                    })
                    .catch(function (error) {
                        console.log("Requestfailed", error);
                    });
            });
        }
    }
    useEffect(() => {
        getMinerInfo()
        getSummary();
        const interval = setInterval(() => {
            getMinerInfo()
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (props.status > 0) {
            setStakePend(props.status)
        }


    }, [props.status])


    useEffect(() => {
        if (props.statuspend > 0) {
            setUnstakePend(props.statuspend)
        }

    }, [props.statuspend])


    useEffect(() => {
        console.log("stakepend---", stakePend, "----", "unstakepend", unstakePend)

    }, [stakePend, unstakePend])



    return (
        <div>

            {(stakePend != 0 || unstakePend != 0) && (
                <>
                    <div
                        style={{
                            "font-size": "11px",
                            "background-color": "rgb(255 199 38)",
                            "border-radius": "8px",
                            fontWeight: "700",
                            display: "flex",
                        }}
                    >
                        {stakePend != 0 && (
                            <span
                                style={{
                                    color: "rgb(37 50 55)",
                                    "text-align": "center",
                                    display: "inline-block",
                                    margin: "0px 5px 0px 11px",
                                    width: "100%",
                                }}
                            >
                                <p
                                    style={{
                                        "margin-top": "15px",
                                        margin: "6px 0px",
                                        color: "rgb(37 50 55)",
                                    }}
                                >
                                    PENDING STAKES{" "}
                                    <p
                                        style={{ color: "rgb(37 50 55)", marginBottom: "auto" }}
                                    >
                                        {stakePend} MINERS
                                    </p>
                                </p>
                            </span>
                        )}

                        {unstakePend != 0 && (
                            <span
                                style={{
                                    color: "rgb(37 50 55)",
                                    "text-align": "center",
                                    display: "inline-block",
                                    float: "right",
                                    margin: "0px 9px 0px 7px",
                                    width: "100%",
                                }}
                            >
                                <p
                                    style={{
                                        "margin-top": "15px",
                                        margin: "6px 0px",
                                        color: "rgb(37 50 55)",
                                    }}
                                >
                                    PENDING UNSTAKES{" "}
                                    <p
                                        style={{ color: "rgb(37 50 55)", marginBottom: "auto" }}
                                    >
                                        {unstakePend} MINERS
                                    </p>
                                </p>
                            </span>
                        )}
                    </div>
                    <div
                        style={{
                            "font-size": "10px",
                            "background-color": "rgb(255 199 38)",
                            "border-radius": "8px",
                            fontWeight: "700",
                            display: "flex",
                            textTransform: "uppercase",
                            margin: "10px 0px",
                        }}
                    >
                        <span
                            style={{
                                "text-align": "center",
                                width: "100%",
                            }}
                        >
                            <p
                                style={{
                                    margin: "7px 0px",
                                    color: "rgb(37 50 55)",
                                }}
                            >
                                Miner transfer may take a few minutes.
                                <br />
                                Please be patient
                            </p>
                        </span>
                    </div>
                </>
            )}


        </div>
    );
}
