import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
import { Spinner } from "reactstrap";
import loader from "../../images/loader.png";

export default function LoaderCard(props) {
  let navigate = useNavigate();

  return (
    <>
      {" "}
      <div class="awaitingconfirmsec txcards"  style={props.bg=="dark" ? {background:"#e0e7ea",gap:"20px"} : {gap:"20px"}}>
        <p class="text1">Awaiting Confirmation</p>
        <img src={loader} alt="Loader" class="loader-image" style={{height:"66px"}}/>
      </div>
    </>
  );
}
