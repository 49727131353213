import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import LoaderCard from "../../../LoaderCard/LoaderCard";
import ConfirmCard from "../../../LoaderCard/ConfirmCard";
import ErrorCard from "../../../LoaderCard/ErrorCard";
import dataVal from "../../../../data/Abis.json";

import plus from "../../../../images/plusss.svg";
import PoolData from "../../PoolData";

export default function LiquidityAddWidget(props) {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const datatok = [
    {
      id: 1,
      label: "USDT",
      icon: "usdticon",
      tokencontract: "0x55d398326f99059fF775485246999027B3197955",
    },
    {
      id: 2,
      label: "EGOLD (V2)",
      icon: "egoldicon",
      tokencontract: "0xBE76F927d274072266caDe09Daa54750cd4293a1",
    },
  ];

  const [addUsdtamnt, setaddUsdtamnt] = useState(null);
  const [addEgoldamnt, setaddEgoldamnt] = useState(null);
  const [usdtBalance, setUsdtBalance] = useState("...");
  const [egoldBalance, setEgoldBalance] = useState("...");
  const [approveUSDT, setapproveUSDT] = useState(false);
  const [approveEGOLD, setapproveEGOLD] = useState(false);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [tok1approve, setTok1approve] = useState(false);
  const [tok2approve, setTok2approve] = useState(false);

  useEffect(() => {
    if (isActive) {
      getSummary();
    } else {
      console.log("inactive");
      setaddUsdtamnt("");
      setaddEgoldamnt("");
      setUsdtBalance(0.0);
      setEgoldBalance(0);
      getSummary();
    }
  }, [account]);

  const getSummary = async () => {
    const web3 = await new Web3(process.env.REACT_APP_RPC);
    const usdttokencontractInstance = await new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_USDT_ADDR
    );
    const egoldcontractInstance = new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLDV2_ADDR
    );

    if (localStorage.getItem("acct") && account) {
      await egoldcontractInstance.methods
        .balanceOf(account)
        .call({}, function (e, res) {
          console.log("egold -- ", res);
          setEgoldBalance(noround(web3.utils.fromWei(res), 4));
        });
      await usdttokencontractInstance.methods
        .balanceOf(account)
        .call({}, function (e, res) {
          console.log("BALANCE USDT", web3.utils.fromWei(res));
          setUsdtBalance(noround(parseFloat(web3.utils.fromWei(res)), 2));
        });
    }
  };

  const getOnchangeval = async (e) => {
    setaddUsdtamnt(e);
    if (e > 0) {
      const web3 = await new Web3(connector.provider);

      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        dataVal.pcfactoryabi,
        process.env.REACT_APP_PANCAKE_FACTORY_ADDR
      );
      await pancakeFactorycontractInstance.methods
        .getPair(
          process.env.REACT_APP_USDT_ADDR,
          process.env.REACT_APP_EGOLDV2_ADDR
        )
        .call({}, function (err, res) {
          const liquidityInstance = new web3.eth.Contract(
            dataVal.liquidityabi,
            res
          );
          liquidityInstance.methods
            .getReserves()
            .call({}, async function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              if (res) {
                console.log("reserves ress-",res)
                setaddEgoldamnt(
                  parseFloat(
                    e /
                    (web3.utils.fromWei(res[0]) / web3.utils.fromWei(res[1]))
                  ).toFixed(4)
                );
              }
            });
        });
    } else {
      setaddEgoldamnt(0.0);
    }
  };
  const getOnchangevalT = async (e) => {
    setaddEgoldamnt(e);
    if (e > 0) {
      const web3 = new Web3(connector.provider);

      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        dataVal.pcfactoryabi,
        process.env.REACT_APP_PANCAKE_FACTORY_ADDR
      );
      await pancakeFactorycontractInstance.methods
        .getPair(
          process.env.REACT_APP_EGOLDV2_ADDR,
          process.env.REACT_APP_USDT_ADDR
        )
        .call({}, function (err, res) {
          const liquidityInstance = new web3.eth.Contract(
            dataVal.liquidityabi,
            res
          );
          liquidityInstance.methods
            .getReserves()
            .call({}, async function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              if (res) {
                setaddUsdtamnt(
                  parseFloat(
                    e /
                    (web3.utils.fromWei(res[1]) / web3.utils.fromWei(res[0]))
                  ).toFixed(4)
                );
              }
            });
        });
    } else {
      setaddUsdtamnt(0.0);
    }
  };

  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }


  async function approveUsdtToken() {
    setapproveUSDT(false);
    if (Number(addUsdtamnt) > Number(usdtBalance)) {
      setprocessState({ state: "error", data: "Insufficient Balance" });
    } else {
      setprocessState({ state: "...", data: null });
      setTok1approve(true);
      const webb3 = new Web3(connector.provider);
      const usdttokencontractInstance = await new webb3.eth.Contract(
        dataVal.tokenabi,
        process.env.REACT_APP_USDT_ADDR
      );
      try {

        const estimatedGas = await usdttokencontractInstance.methods
        .approve(
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
          webb3.utils.toWei(addUsdtamnt.toString())
        )
          .estimateGas({ from: account });

        await usdttokencontractInstance.methods
          .approve(
            process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
            webb3.utils.toWei(addUsdtamnt.toString())
          )
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setapproveUSDT(true);
            setTok1approve(false);
          })
          .on("error", function (error, receipt) {
            checkerror(error);
            setTok1approve(false);
            setapproveUSDT(false);
            console.log("error", error);
          });
      } catch (e) {
        console.log(e);
        setTok1approve(false);
        checkerror(e);
      }
    }
  }

  async function approveEGOLDToken() {
    setapproveEGOLD(false);
    if (Number(addEgoldamnt) > Number(egoldBalance)) {
      setprocessState({ state: "error", data: "Insufficient Balance" });
    } else {
      setprocessState({ state: "...", data: null });
      setTok2approve(true);
      const webb3 = new Web3(connector.provider);
      const egoldtokencontractInstance = await new webb3.eth.Contract(
        dataVal.tokenabi,
        process.env.REACT_APP_EGOLDV2_ADDR
      );

      try {

        const estimatedGas = await egoldtokencontractInstance.methods
        .approve(
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
          webb3.utils.toWei(addEgoldamnt.toString())
        )
          .estimateGas({ from: account });

        await egoldtokencontractInstance.methods
          .approve(
            process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
            webb3.utils.toWei(addEgoldamnt.toString())
          )
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setapproveEGOLD(true);
            setTok2approve(false);
          })
          .on("error", function (error, receipt) {
            checkerror(error);
            setapproveEGOLD(false);
            setTok2approve(false);
            console.log("error", error);
          });
      } catch (e) {
        console.log(e);
        setTok2approve(false);
        checkerror(e);
      }
    }
  }

  const addLiquidityToken = async () => {
    const web3 = new Web3(connector.provider);
    const addLiquidityInstance = new web3.eth.Contract(
      dataVal.pcrouterabi,
      process.env.REACT_APP_PANCAKE_ROUTER_ADDR
    );
    var today = new Date();
    var futureDate = new Date(today.getTime() + 60000);
    let usdtMin = parseFloat(0.95 * addUsdtamnt)
      .toFixed(16)
      .toString();
    let egoldMin = parseFloat(0.95 * addEgoldamnt)
      .toFixed(16)
      .toString();
    usdtMin = String(usdtMin);
    egoldMin = String(egoldMin);

    try {
      setprocessState({ state: "processing", data: null });

      const estimatedGas = await addLiquidityInstance.methods
      .addLiquidity(
        process.env.REACT_APP_USDT_ADDR,
        process.env.REACT_APP_EGOLDV2_ADDR,
        web3.utils.toWei(addUsdtamnt.toString()),
        web3.utils.toWei(addEgoldamnt.toString()),
        web3.utils.toWei(usdtMin.toString()),
        web3.utils.toWei(egoldMin.toString()),
        account,
        futureDate.getTime()
      )
      .estimateGas({ from: account });

      await addLiquidityInstance.methods
        .addLiquidity(
          process.env.REACT_APP_USDT_ADDR,
          process.env.REACT_APP_EGOLDV2_ADDR,
          web3.utils.toWei(addUsdtamnt.toString()),
          web3.utils.toWei(addEgoldamnt.toString()),
          web3.utils.toWei(usdtMin.toString()),
          web3.utils.toWei(egoldMin.toString()),
          account,
          futureDate.getTime()
        )
        .send({
          from: account,
          gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
          gasPrice: await web3.eth.getGasPrice(),
        })
        .on("receipt", async function (res) {
          setapproveEGOLD(false);
          setapproveUSDT(false);
          getSummary();
          setprocessState({ state: "done", data: res.transactionHash });
        })
        .on("error", function (error, receipt) {
          checkerror(error);
          console.log("error", error);
        });
    } catch (e) {
      console.log(e);
      checkerror(e);
    }
  };

  function getMaxF() {
    setaddUsdtamnt(usdtBalance);
    getOnchangeval(usdtBalance);
  }
  function getMaxT() {
    setaddEgoldamnt(egoldBalance);
    getOnchangevalT(egoldBalance);
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <>
    <PoolData from={datatok[0]} to={datatok[1]} />
      <div class="row minertabsec" style={{ marginBottom: "14px" }}>
            <ul
              class="nav nav-pills mb-1 p-3 pt-1 pb-0 liquiditytabs"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation" style={{ width: "50%" }}>
                <button
                  class="nav-link typetabbtn  brltb active "
                  id="pills-gseries-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-gseries"
                  type="button"
                  role="tab"
                  aria-controls="pills-gseries"
                  aria-selected="false"
                  onClick={() => {
                    navigate("/trade/liquidity");
                  }}
                >
                  EGOLD (V2)
                </button>
              </li>
              <li class="nav-item" role="presentation" style={{ width: "50%" }}>
                <button
                  class="nav-link typetabbtn brrtb"
                  id="pills-sseries-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-sseries"
                  type="button"
                  role="tab"
                  aria-controls="pills-sseries"
                  aria-selected="true"
                  onClick={() => {
                    navigate("/trade/liquidityV1");
                  }}
                >
                  EGOLD (V1)
                </button>
              </li>
            </ul>
          </div>
      <div class="detailCard secpadding mb20">
        <div class="fromreactangle pr mb20">
          <div class="p15">
            <p class="fs12 mb10">From</p>
            <p class="fromreactp">
              <input
                className="swapinput"
                placeholder="0.00"
                value={addUsdtamnt}
                onChange={(e) => getOnchangeval(e.target.value)}
              />{" "}
              <a class="selecttokendrop">
                <span
                  class="float-right fromreactspan"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="usdticon"></span>
                  USDT{" "}
                </span>
              </a>
            </p>
            <p class="fs12">
              Balance: {usdtBalance}{" "}
              <span
                class="float-right yellowtext fs14"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => getMaxF()}
              >
                Use Max
              </span>
            </p>
          </div>
          <div class="updownimgb">
            <img src={plus} alt="" />
          </div>
        </div>
        <div class="fromreactangle mb20">
          <div class="p15">
            <p class="fs12 mb10">To</p>
            <p class="fromreactp">
              <input
                className="swapinput"
                placeholder="0.00"
                value={addEgoldamnt}
                onChange={(e) => getOnchangevalT(e.target.value)}
              />{" "}
              <a class="selecttokendrop">
                <span
                  class="float-right fromreactspan"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="egoldicon"></span>
                  EGOLD (V2){" "}
                </span>
              </a>
            </p>
            <p class="fs12">
              Balance: {egoldBalance}{" "}
              <span
                class="float-right yellowtext fs14"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => getMaxT()}
              >
                Use Max
              </span>
            </p>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <div className="ybbtndiv mb10" style={{ width: "50%" }}>
            <button
              type="button"
              className="btn-color-primary"
              style={{ width: "100%", border: "0px" }}
              disabled={approveUSDT}
              onClick={() => {
                approveUsdtToken();
              }}
            >
              {tok1approve ? (
                <Spinner size="sm" />
              ) : (
                <span>
                  Approve <br className="mobbr" />
                  USDT
                </span>
              )}
            </button>
          </div>
          <div className="ybbtndiv mb10" style={{ width: "50%" }}>
            <button
              type="button"
              className="btn-color-primary"
              style={{ width: "100%", border: "0px" }}
              disabled={approveEGOLD}
              onClick={() => {
                approveEGOLDToken();
              }}
            >
              {tok2approve ? (
                <Spinner size="sm" />
              ) : (
                <span>
                  Approve <br className="mobbr" />
                  EGOLD (V2)
                </span>
              )}
            </button>
          </div>
        </div>
        <button
          type="button"
          className="btn-color-primary"
          style={{ cursor: "pointer", width: "100%", border: "0px" }}
          onClick={() => {
            addLiquidityToken();
          }}
          disabled={approveUSDT && approveEGOLD ? false : true}
        >
          Add Liquidity
        </button>
        {processState.state == "..." ? (
          ""
        ) : processState.state == "processing" ? (
          <LoaderCard />
        ) : processState.state == "done" ? (
          <ConfirmCard tx={processState.data} />
        ) : (
          <ErrorCard err={processState.data} />
        )}
      </div>
    </>
  );
}
