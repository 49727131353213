import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ConfirmCard(props) {
  let navigate = useNavigate();

  return (
    <>
      <div class="transictionconfirmsec txcards" style={props.bg=="dark" ? {background:"#e0e7ea",gap:"20px"} : {gap:"20px"}}>
        <p class="text1">Transaction Confirmed </p>

        <a
          href={"https://bscscan.com/tx/" + props.tx}
          target="_blank"
          class="btn-outline-grey"
          style={{background:"white"}}
        >
          View Transaction
        </a>
      </div>
    </>
  );
}
