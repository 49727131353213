import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { Spinner } from 'reactstrap';
import plus from "../../../images/plusss.svg";
import { useWeb3React } from "@web3-react/core";
import dataVal from "../../../data/Abis.json";
import ErrorCard from "../../LoaderCard/ErrorCard";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
export default function LPCollectWidget(props) {
  let navigate = useNavigate();
  const Web3 = require("web3");
  const { account, isActive, connector } = useWeb3React();


  const BUSDFARM_ABI = dataVal.lpfarmabi;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const PANCAKE_FACTORY_ABI = dataVal.pancakeFactoryAbi
  const BUSD_ADDRESS = dataVal.busdtokencontract;
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const TOKEN_ABI = dataVal.busdtokenabi;

  const [depositedamnt, setdepositedamnt] = useState("0.00");
  const [farmingshare, setfarmingshare] = useState("0.00");
  const [farmedVal, setfarmedVal] = useState("0.00");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [submitLoader, setsubmitLoader] = useState(false);


  useEffect(() => {
    console.log("actiiivee", account);
    if (isActive) {
      console.log("active");
      fetchLQBalance();
      getfarmedInfo();
    } else {
      console.log("inactive");
      setdepositedamnt("0.00");
      setfarmingshare("0.00");
      setfarmedVal("0.00");
      getfarmedInfo();
    }
  }, [account]);



  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct") && account) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        PANCAKE_FACTORY_ABI,
        PANCAKE_FACTORY_ADDRESS
      );
      await pancakeFactorycontractInstance.methods
        .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
        .call({}, function (e, res) {
          const LQInstance = new web3.eth.Contract(TOKEN_ABI, res);
          LQInstance.methods
            .balanceOf(BUSD_EGOLD_FARM_ADDRESS)
            .call({}, function (e, resLQStake) {

              getLPTdepositblnce(
                parseFloat(web3.utils.fromWei(resLQStake)).toFixed(4)
              );
            });
        });
    }
  };


  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  const getLPTdepositblnce = async (stakedval) => {
    if (localStorage.getItem("acct") && account) {
      const webb3 = new Web3(process.env.REACT_APP_RPC);
      const depositLPTInstance = await new webb3.eth.Contract(
        BUSDFARM_ABI,
        BUSD_EGOLD_FARM_ADDRESS
      );
      await depositLPTInstance.methods
        .deposited(0, account)
        .call({}, function (e, resBalance) {


          setdepositedamnt(noround(webb3.utils.fromWei(resBalance), 4));
          var amnt =
            parseFloat(webb3.utils.fromWei(resBalance)).toFixed(4) /
            Number(stakedval);
          amnt = amnt * 100;
          console.log("SHARE", amnt);
          setfarmingshare(parseFloat(amnt).toFixed(6));
        });
    }
  };

  const getfarmedInfo = async () => {
    const webb3 = new Web3(process.env.REACT_APP_RPC);
    const farmedLPTInstance = await new webb3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );
    if (localStorage.getItem("acct") && account) {
      await farmedLPTInstance.methods
        .pending(0, account)
        .call({}, function (e, resFarmed) {
          console.log("reesss", resFarmed)
          setfarmedVal(parseFloat(webb3.utils.fromWei(resFarmed)).toFixed(4));
        });
    }
  };

  const withdrawYield = async () => {
    setsubmitLoader(true)
    setprocessState({
      state: "...",
      data: null
    })
    const webb3 = new Web3(connector.provider);
    const withdrawLPTInstance = await new webb3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );
    if (depositedamnt > 0.0) {
      setprocessState({
        state: "processing",
        data: null
      })
      try {

        const estimatedGas = await withdrawLPTInstance.methods
        .withdraw(0, webb3.utils.toWei(depositedamnt.toString()))
          .estimateGas({ from: account });

        await withdrawLPTInstance.methods
          .withdraw(0, webb3.utils.toWei(depositedamnt.toString()))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await webb3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            fetchLQBalance();
            getfarmedInfo();
            setsubmitLoader(false)
            setprocessState({
              state: "done",
              data: res.transactionHash

            });
          })
          .on("error", function (error, receipt) {
            setsubmitLoader(false)
            console.log("error");
            checkerror(error)
          });
      } catch (error) {
        console.log("error-", error);
        // setTokenapp(false)
        setsubmitLoader(false)
        checkerror(error)
      }

    } else {
      setsubmitLoader(false)
      console.log("here jeree")
      setprocessState({
        state: "error",
        data: "Insufficient balance"
      });
    }
  };
  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }
  return (
    <div class="detailCard mb20">
      <div class="bg-white secpadding brrr">
        <div class="d-flex justify-content-between mb20">
          <div>
            <p class="walletsecp">Your deposit (LPT)</p>
            <p class="walletsecp2" style={{ textAlign: "left" }}>{depositedamnt}</p>
          </div>
          <div>
            <p class="walletsecp">Farming share</p>
            <p class="walletsecp2">{farmingshare}%</p>
          </div>
          <div>
            <p class="walletsecp">Farmed(Egold)</p>
            <p class="walletsecp2">${farmedVal}</p>
          </div>
        </div>
        <a class="btn-color-primary" style={{ cursor: "pointer" }} onClick={() => withdrawYield()}>
          {/* {
            submitLoader ? <Spinner color='black' style={{ height: 16, width: 16 }} /> : "Collect yield"
          } */}
          Collect yield
        </a>
        {processState.state == "..." ? (
          ""
        ) : processState.state == "processing" ? (
          <LoaderCard />
        ) : processState.state == "done" ? (
          <ConfirmCard tx={processState.data} />
        ) : (
          <ErrorCard err={processState.data} />
        )}

      </div>
      {/* {processState.state == "..." ? (
        ""
      ) : processState.state == "done" ? (
        <div class="transictionconfirmsec" style={{ padding: 20, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
          <p class="text1 mb20">Transaction Confirmed </p>

          <a
            href={"https://bscscan.com/tx/" + processState.data}
            target="_blank"
            class="btn-outline-grey mb20"
          >
            View Transaction
          </a>
        </div>
      ) : (
        <div class="transictionconfirmsec">
          <p class="text1 mb20" style={{ color: "red" }}>
            {processState.data}
          </p>
        </div>
      )} */}

{/* 
      <div class="lightblue-bg secpadding brblr">
        <p class="text2">The earning cap is determined by the amount of XUSD you have purchased. For example, if you have purchased 100 XUSD, you can earn up to 500 XUSD based on the 500% earning cap. You can increase your earning cap by purchasing more XUSD.</p>
      </div> */}
    </div>
  );
}
