import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import dataVal from "../data/Abis.json";
import { useWeb3React } from "@web3-react/core";
import LoaderCard from "./LoaderCard/LoaderCard";
import ConfirmCard from "./LoaderCard/ConfirmCard";
import ErrorCard from "./LoaderCard/ErrorCard";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const NFT_ABI = dataVal.nftAbi;
const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
const nftContractInstance = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);

export default function Transfer() {
  let navigate = useNavigate();
  let params = useParams();
  const [minerName, setminerName] = useState("...");
  const [recieveAddr, setrecieveAddr] = useState("");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const { account, isActive, connector } = useWeb3React();

  let miner_id = params.id;

  useEffect(() => {
    getMinerDetails();
  }, []);

  async function getMinerDetails() {
    await nftContractInstance.methods
      .fetchMinerInfo(params.id)
      .call()
      .then((res) => {
        console.log("response", res.minerName);
        setminerName(res.minerName);
      });
  }

  async function safeTransfer() {
    let fromaddr = localStorage.getItem("acct");
    if (!web3.utils.isAddress(recieveAddr)) {
      setprocessState({
        state: "error",
        data: "Invalid Receiver Address",
      });
    } else {
      setprocessState({ state: "processing", data: null });
      const webb3 = new Web3(connector.provider);
      const transferContract = new webb3.eth.Contract(NFT_ABI, NFT_ADDRESS);

      try {
        const estimatedGas = await transferContract.methods
          .safeTransferFrom(fromaddr, recieveAddr, miner_id)
          .estimateGas({ from: account });

        await transferContract.methods
          .safeTransferFrom(fromaddr, recieveAddr, miner_id)
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (fres) {
            setprocessState({ state: "done", data: fres.transactionHash });
          })
          .on("error", function (error) {
            console.log("error", JSON.stringify(error.message));
          });
      } catch (e) {
        console.log(e);
        setprocessState({ state: "error", data: JSON.stringify(e.message) });
      }
    }
  }

  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="Transfer Miner" ReRoute="-1" />
          <div class="detailCard secpadding mb20">
            <div class="minerAvailableCard mb10">
              <div class="minerAvailableTitle mb20">
                EGOLD Miner <span class="">{minerName}</span>
              </div>

              <div class="minerAvailableImage">
                {minerName !== "..." ? (
                  <img
                    src={require("../images/miners/" + minerName + ".png")}
                    class="img-fluid"
                  />
                ) : null}
              </div>

              <a class="minerAvailablebtn">Miner ID: {miner_id}</a>
            </div>

            <label for="receiver_address" class="form-label">
              Receiver Address
            </label>
            <input
              type="email"
              class="form-control mb20"
              id="receiver_address"
              value={recieveAddr}
              onChange={(e) => {
                setrecieveAddr(e.target.value);
              }}
            />
            <a
              class="btn-color-primary mb20"
              style={{ cursor: "pointer" }}
              onClick={() => {
                safeTransfer();
              }}
            >
              Transfer Miner
            </a>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard bg="dark" />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} bg="dark" />
          ) : (
            <ErrorCard err={processState.data} bg="dark" />
          )}
        </div>

        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            You can tranfer your Miner ANFT to another wallet. Please provide
            external wallet address that you would like to send your miner to.
          </p>
        </div>
      </div>
    </>
  );
}
