import React, { useEffect, useState } from "react";
import PillsMenu from "./Menu/PillsMenu";
import TradePillsMenu from "./Trade/TradePillsMenu";
import TradeWidget from "./Trade/Swap/TradeWidget";
import WalletButton from "./Buttons/WalletButton/WalletButton";
import { useWeb3React } from "@web3-react/core";

export default function Swap() {
  const { account, isActive, connector } = useWeb3React();

  return (
    <>
      <div
        class="mainsection "
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brrr mb20">
          <PillsMenu activeval="trade" />
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
                <TradePillsMenu activeval="swap" />
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-swap" role="tabpanel" aria-labelledby="pills-swap-tab" tabindex="0">
                    <TradeWidget />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="overlay"
          style={
            localStorage.getItem("acct") || isActive ? { display: "none" } : {}
          }
        >
          <div className="connectinoverlay">
            {" "}
            <WalletButton />
          </div>
          Please connect your wallet to continue
        </div>
      </div>
    </>
  );
}
