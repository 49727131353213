import React, { useState, useEffect } from "react";
import dataVal from "../../../data/Abis.json";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";
const Web3 = require("web3");

export default function MinerInfo(props) {
    const web3 = new Web3(process.env.REACT_APP_RPC);
    const { account, isActive, connector } = useWeb3React();
    const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
    const MINING_ADDRESS = process.env.REACT_APP_MINING_ADDR;
    let navigate = useNavigate();

    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });
    const [maxClaimCount, setmaxClaimCount] = useState(10);
    const [minerName, setMinerName] = useState("...");
    const [approveLoader, setapproveLoader] = useState(false);
    const [submitLoader, setsubmitLoader] = useState(false);
    const [minerPrice, setMinerPrice] = useState("...");
    const [minerHash, setMinerHash] = useState("...");
    const [pending, setpending] = useState("...");
    const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
    const [stakePend, setStakePend] = useState(0);
    const [unstakePend, setUnstakePend] = useState(0);

    useEffect(() => {
        getMinerInfo();
    }, []);

    const getMinerInfo = async () => {
        const contractInstance = new web3.eth.Contract(
            dataVal.mregistryabi,
            process.env.REACT_APP_MINER_REGISTRY
        );

        await contractInstance.methods
            .fetchMinerInfo(props.type.mType)
            .call()
            .then((res) => {
                setMinerName(res.name);
                setMinerPrice(parseInt(res.minerBaseRate));
                setMinerHash(parseInt(res.hashRate));
            });
        const farmInstance = new web3.eth.Contract(
            dataVal.farmAbi,
            process.env.REACT_APP_FARMING_ADDR
        );
        await farmInstance.methods
            .pending(0, props.type._id)
            .call({}, async function (e, pending) {
                setpending(web3.utils.fromWei(pending));
            });
    };

    async function approveMiner(minID) {
        setTokenapp(true);
        setapproveLoader(true);
        setprocessState({ state: "...", data: null });
        const webb3 = new Web3(connector.provider);

        const NFTcontractInstance = await new webb3.eth.Contract(
            dataVal.nftAbi,
            NFT_ADDRESS
        );
        try {
            const estimatedGas = await NFTcontractInstance.methods
                .approve(process.env.REACT_APP_MINING_ADDR, minID)
                .estimateGas({ from: account });

            await NFTcontractInstance.methods
                .approve(process.env.REACT_APP_MINING_ADDR, minID)
                .send({
                    from: account,
                    gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                    gasPrice: await web3.eth.getGasPrice(),
                })
                .on("receipt", async function (res) {
                    setapproveLoader(false);
                    setTokenapp(false);
                })
                .on("error", function (error, receipt) {
                    setapproveLoader(false);
                    console.log("error");
                    checkerror(error);
                });
        } catch (error) {
            setapproveLoader(false);
            checkerror(error);
        }
    }

    async function mine(mid) {
        setsubmitLoader(true);
        setprocessState({ state: "...", data: null });
        const web3 = new Web3(connector.provider);
        const miningcontractInstance = await new web3.eth.Contract(
            dataVal.miningAbi,
            MINING_ADDRESS
        );
        try {
            setprocessState({ state: "processing", data: null });

            const estimatedGas = await miningcontractInstance.methods
                .stakeNFT(mid)
                .estimateGas({ from: account });

            await miningcontractInstance.methods
                .stakeNFT(mid)
                .send({
                    from: account,
                    gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                    gasPrice: await web3.eth.getGasPrice(),
                })
                .on("receipt", async function (res) {
                    setsubmitLoader(false);

                    var x = [];
                    if (localStorage.getItem("StakePending"))
                        x = JSON.parse(localStorage.getItem("StakePending"));
                    x.push(res.transactionHash);
                    localStorage.setItem("StakePending", JSON.stringify(x));
                    getMinerInfo();
                    props.setpend(x.length);
                    setTokenapp(true);
                    setprocessState({ state: "done", data: res.transactionHash });
                })
                .on("error", function (error, receipt) {
                    setsubmitLoader(false);
                    checkerror(error);
                });
        } catch (error) {
            setsubmitLoader(false);
            checkerror(error);
        }
    }

    function checkerror(err) {
        if (
            err.message ==
            "Please pass numbers as strings or BN objects to avoid precision errors." ||
            err.message.includes(
                "while converting number to string, invalid number value "
            )
        ) {
            setprocessState({
                state: "error",
                data: "Please provide a valid input",
            });
        } else if (JSON.stringify(err.message).includes("transaction underpriced"))
            setprocessState({
                state: "error",
                data: "Transaction was underpriced. Please try increasing the gas price",
            });
        else
            setprocessState({
                state: "error",
                data: JSON.stringify(err.message),
            });
    }

    return (
        <div>
            <div class="mineraccordian">
                <div class="accordion" id="accordionExample">
                    <div class="mineaccordianall">
                        <div class="mineaccorsec">
                            <div class="minerstate">
                                <div class="mineaccorimg">
                                    {minerName !== "..." ? (
                                        <img
                                            src={require("../../../images/miners/" +
                                                minerName +
                                                ".png")}
                                            alt=""
                                            class="img-fluid"
                                        />
                                    ) : null}
                                </div>
                                <span class="inactivebadge">Inactive</span>
                            </div>
                            <div class="mineaccorbtnsec">
                                <div class="mineaccorbtns mb15">
                                    <a class="mineaccorbtn active">Miner Id: {props.type._id}</a>
                                    <a class="mineaccorbtn">Power: {minerHash} Mhs</a>
                                </div>
                                <div class="mineaccorbtns">
                                    <a class="mineaccorbtn">Miner type: {minerName}</a>
                                    <a class="mineaccorbtn">
                                        Claimed:{" "}
                                        {parseInt(
                                            props.tokendata.data.find(
                                                (x) => x.mid === props.type._id
                                            ) != undefined
                                                ? props.tokendata.data.find(
                                                    (x) => x.mid === props.type._id
                                                ).ctr
                                                : 0
                                        )}
                                        / {maxClaimCount}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            class="accordion-item mineraccordianitem mineaccoritem"
                            style={{ background: "#e0e7ea" }}
                        >
                            <h2 class="accordion-header" id="headingOne" >
                                <div style={{
                                    display: "flex", backgroundColor: "#e0e7ea",
                                    color: "#4f6b75"
                                }} data-bs-toggle="collapse"
                                    data-bs-target={"#collapseOne" + props.index}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                    className="accordion-button collapsed">
                                    <div style={{
                                        flexBasis: "40%"
                                    }}>

                                        <button
                                            class=" mineraccordianbtn minebtn"
                                            type="button"
                                            style={{
                                                "background-color": "#e0e7ea",
                                                color: "#4f6b75",
                                                border: "none"
                                            }}

                                        >
                                            {" "}
                                            Miner Details{" "}
                                        </button>
                                    </div>
                                    <div className="mdetbtns" style={{ display: "flex", gap: 25, justifyContent: "end", flexBasis: "52%" }}>
                                        <button
                                            class="btn-outline-color-secondary"
                                            style={{ cursor: "pointer" }}
                                            disabled={!tokenapp}
                                            onClick={(e) => {
                                                approveMiner(props.type._id);
                                            }}
                                        >
                                            {approveLoader ? (
                                                <Spinner
                                                    color="black"
                                                    style={{ height: 16, width: 16 }}
                                                />
                                            ) : (
                                                "Approve"
                                            )}
                                        </button>
                                        <button
                                            class="btn-color-primary"
                                            disabled={tokenapp}
                                            style={{ cursor: "pointer", border: "0px" }}
                                            onClick={(e) => {
                                                tokenapp ? null : mine(props.type._id);
                                            }}
                                        >
                                            {submitLoader ? (
                                                <Spinner
                                                    color="black"
                                                    style={{ height: 16, width: 16 }}
                                                />
                                            ) : (
                                                "Activate miner"
                                            )}
                                        </button>
                                    </div>
                                </div>

                            </h2>
                            <div
                                id={"collapseOne" + props.index}
                                class="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <div class="accordion-body mineraccordianbody">
                                    <div class="performancehistroy">
                                        <div>
                                            <p class="minerownep">Mining fuel (Egold)</p>
                                            <p class="minerownep2 redtext">
                                                {/* {pending != "..." ? parseFloat(pending * 0.5).toFixed(4) : "..."}
*/}
                                                50% of Output
                                            </p>
                                        </div>
                                        <div>
                                            <p class="minerownep">Depreciation (Egold)</p>
                                            <p class="minerownep2 redtext">
                                                {pending != "..." &&
                                                    props.tokendata.data.find(
                                                        (x) => x.mid === props.type._id
                                                    ) != undefined
                                                    ? parseInt(
                                                        props.tokendata.data.find(
                                                            (x) => x.mid === props.type._id
                                                        ).ctr
                                                    ) == 0
                                                        ? "0"
                                                        : (Number(
                                                            props.tokendata.data.find(
                                                                (x) => x.mid === props.type._id
                                                            ).ctr
                                                        ) +
                                                            1) *
                                                        5 -
                                                        5
                                                    : 0}
                                                % Of Output
                                            </p>
                                        </div>
                                        <div>
                                            <p class="minerownep">Hosting (Egold) </p>
                                            <p class="minerownep2 redtext">
                                                {/* - {pending != "..." ? parseFloat(pending * 0.01).toFixed(4) : "..."} EGOLD */}
                                                1% of Output
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            padding: "5px 0px",
                                            background: "#c1cfd5",
                                            borderRadius: "0px 0px 7px 7px",
                                        }}
                                    >
                                        {/* <div
                class="selectcurbtnsec appactiveminer"
                style={{ paddingBottom: "0px" }}
            >
                <button
                    class="btn-outline-color-secondary"
                    style={{ cursor: "pointer" }}
                    disabled={!tokenapp}
                    onClick={(e) => {
                        approveMiner(props.type._id);
                    }}
                >
                    {approveLoader ? (
                        <Spinner
                            color="black"
                            style={{ height: 16, width: 16 }}
                        />
                    ) : (
                        "Approve"
                    )}
                </button>
                <button
                    class="btn-color-primary"
                    disabled={tokenapp}
                    style={{ cursor: "pointer", border: "0px" }}
                    onClick={(e) => {
                        tokenapp ? null : mine(props.type._id);
                    }}
                >
                    {submitLoader ? (
                        <Spinner
                            color="black"
                            style={{ height: 16, width: 16 }}
                        />
                    ) : (
                        "Activate miner"
                    )}
                </button>
            </div> */}
                                        <div
                                            class="selectcurbtnsec appactiveminer "
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                navigate("/transfer/" + props.type._id);
                                            }}
                                        >
                                            <a class="btn-color-primary w-100">Transfer Miner</a>
                                        </div>
                                    </div>
                                    {processState.state == "..." ? (
                                        ""
                                    ) : processState.state == "processing" ? (
                                        <div style={{ margin: "20px" }}>
                                            <LoaderCard />
                                        </div>
                                    ) : processState.state == "done" ? (
                                        <div style={{ margin: "20px" }}>
                                            <ConfirmCard tx={processState.data} />
                                        </div>
                                    ) : (
                                        <div style={{ margin: "20px" }}>
                                            <ErrorCard err={processState.data} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}